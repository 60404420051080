@import "~antd/dist/antd.css";
@import "../gaxon/styles.css";
@import "../noir-pro/styles.css";

html,
body {
  height: 100%;
  width: 100%;
  font-family: "NoirPro";
}

@media screen and (max-width: 575px) {
  .gx-app-login-wrap {
    padding-top: 20px;
    -webkit-justify-content: center !important;
    -ms-justify-content: center !important;
    justify-content: center !important;
  }
}

.ant-table-thead>tr>th .anticon-filter,
.ant-table-thead>tr>th .ant-table-filter-icon {
  top: 50% !important;
}

/* .ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item-active, .ant-menu-dark .ant-menu-submenu-active, .ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-title:hover, .ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover,
.ant-menu-dark .ant-menu-item:hover > a, .ant-menu-dark .ant-menu-item-active > a, .ant-menu-dark .ant-menu-submenu-active > a, .ant-menu-dark .ant-menu-submenu-selected > a, .ant-menu-dark .ant-menu-submenu-title:hover > a, .ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > a{
  color: #ffffff !important;
} */

.gx-user-popover li {
  width: auto !important;
}

#root,
.ant-layout {
  height: 100vh;
  width: 100%;
}

.ant-layout.with-alert {
  height: calc(100vh - 36px);
}

.highlight {
  color: #038fde;
}

.callback-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-page-header-heading-title {
  margin-right: 0;
}

/* TAG */
.ant-page-header-heading .ant-tag {
  margin-bottom: 0;
}

.ant-tag.PENDING {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}

.ant-tag.CLOSED {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.ant-tag.OPEN {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}

.ant-table-cell>.ant-tag {
  margin: 0;
}

/* END - TAG */

.ant-steps.lead-users-steps {
  display: block;
}

.lead-users-steps.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 50px;
  padding-right: 20px;
  padding-left: 20px;
}

.login-step {
  margin: 10px;
  width: 230px;
}

.csv-btn,
.csv-btn:focus,
.csv-btn:active,
.csv-btn:hover {
  color: inherit;
  padding-right: 45px;
}

.google-logo {
  line-height: 35px !important;
}

.google-logo div {
  height: 35px !important;
  width: 35px !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
}

.google-logo svg {
  width: 35px !important;
  height: 35px !important;
  /* .hidden-client-data,
.hidden-client-data-th {
  display: none;
} */
}

span.hidden-client-code {
  font-weight: 100 !important;
}

.amc-revenue-filter-loader .ant-select-arrow-loading {
  color: red !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #c43530;
}

@media (max-width: 559px) {
  .support {
    height: calc(100vh - 00px);
  }
}
