.user-form .ant-form-item-label {
  text-align: left;
}
.user-form .ant-form-item {
  margin-bottom: 0;
}
.user-form .ant-input-password .ant-input {
  padding: 4px 11px;
}
.user-form .ant-btn {
  margin-top: 10px;
}
@media (max-width: 575px) {
  .user-form .ant-form-item-label[class*="ant-col-"] {
    margin-bottom: 0px;
  }
}
