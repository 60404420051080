 .agreement-preview {
   width: 100% !important;
 }

 .pdf-viewer-button {
   display: flex;
   justify-content: space-around;
 }

 .pdf-viewer-page-text {
   align-self: center;
 }

 .buttons-viewer {
   font-size: 30px;
 }
