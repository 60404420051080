.client-form .ant-form-item-label {
  text-align: left;
}

.client-form .ant-form-item {
  margin-bottom: 0;
}

.client-form .ant-input-password .ant-input {
  padding: 4px 11px;
}

.client-form .ant-btn {
  margin-top: 10px;
}

.client-form .ant-upload .ant-btn {
  margin: 0;
}

.client-form .ant-upload-list-item {
  margin-bottom: 10px;
}

.steps-action {
  display: flex;
  justify-content: center;
  margin-top: 18px;
}

/* .ant-btn.ant-btn-primary{
  margin-bottom: 0px;
} */
@media (max-width: 575px) {
  .client-form .ant-form-item-label[class*='ant-col-'] {
    margin-bottom: 0px;
  }
}

.client-form {
  overflow-y: scroll;
  height: 72vh;
  overflow-x: hidden;
}

.client-email-input {
  text-transform: lowercase;
}

.client-log-table{
  width: 30%;
}

.column-name-type{
  text-transform: capitalize;
}
