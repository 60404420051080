.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.menu-wrapper {
  overflow: auto;
  height: calc(100vh - 100px);
  overflow-x: hidden;
}

.menu-wrapper::-webkit-scrollbar {
  /* display: none; */
  /* width: 2px; */
  scrollbar-width: thin;
  width: 10px;
}

.menu-wrapper::-webkit-scrollbar-track {
  background: #9f8c8b;
}

/* Handle */
.menu-wrapper::-webkit-scrollbar-thumb {
  background: #c43530;
}

/* Handle on hover */
.menu-wrapper::-webkit-scrollbar-thumb:hover {
  background: #9f0e01;
}

.version-print {
  opacity: 0.4;
  margin-bottom: 0;
  text-align: center;
}

.logo {
  cursor: pointer;
  padding: 0 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 12px 0;
}

.logo a {
  text-align: center;
}

.logo img {
  width: 65%;
}

.profile-menu {
  display: flex;
  border: 1px solid #cccccc;
  padding: 8px 0;
  border-radius: 5px;
}

.gx-avatar-role {
  text-transform: capitalize;
  text-align: center;
  font-size: 14px;
}

@media (max-width: 768px) {
  .gx-avatar-role {
    display: none;
  }
}

.ant-menu-submenu-popup .ant-menu.ant-menu-sub.ant-menu-vertical {
  background-color: #003366 !important;
}

.custom-sub-menu div {
  display: flex;
  color: #fff;
  align-items: center;
}

.menu-divider-space {
  padding: 0 20px;
}

.menu-divider {
  border-right: 1px dotted silver;
}

.menu-divider.notification {
  text-align: center;
  font-size: 20px;
}

.menu-divider.notification i {
  font-size: 20px;
}

.header .user a {
  color: inherit;
}

.ant-menu-vertical > .ant-menu-item.menu-list,
.ant-menu-vertical-left > .ant-menu-item.menu-list,
.ant-menu-vertical-right > .ant-menu-item.menu-list,
.ant-menu-inline > .ant-menu-item.menu-list {
  height: 50px;
  line-height: 50px;
  font-size: 17px;
  margin-top: 0px;
}

.ant-menu-vertical > .ant-menu-item.menu-list i,
.ant-menu-vertical-left > .ant-menu-item.menu-list i,
.ant-menu-vertical-right > .ant-menu-item.menu-list i,
.ant-menu-inline > .ant-menu-item.menu-list i {
  font-size: 17px;
}

.anticon.custom-discussions {
  transform: translate3D(0, -1px, 0) scale(1.33);
}

.anticon.custom-discussions svg {
  height: 14px;
}

.ant-menu-item-selected .anticon.custom-discussions,
.ant-menu-item:hover .anticon.custom-discussions {
  fill: #1890ff;
}

.ant-layout-sider .ant-menu .ant-menu-item .icon.menu-profile {
  font-size: 18px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.site-layout .ant-layout-content {
  height: calc(100vh - 64px);
  /* background: #fff; */
  /* margin: 24px 16px; */
  padding: 24px;
  overflow-y: scroll;
}

.layout-drawer .ant-drawer-content {
  /* background-color: #003366 !important; */
  background-color: #03080e !important;
}

.layout-drawer .ant-drawer-header {
  /* background-color: #003366 !important; */
  background-color: #03080e !important;
  padding: 0 !important;
  border: 0 !important;
  text-align: center;
}

.layout-drawer .ant-drawer-title {
  margin: 10px 0;
}

.layout-drawer .ant-drawer-body {
  /* background-color: #003366; */
  background-color: #03080e;
  color: #038fdd;
}

.gx-avatar-name .header-name-ml {
  margin-left: 0.5rem;
}

.content-wrapper {
  max-height: calc(100vh - 74px - 50px - 50px);
  /* overflow: hidden; */
}

.admin-content-wrapper {
  max-height: none;
  overflow: hidden;
}

@media screen and (max-width: 575px) {
  .gx-avatar-name .header-name {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 719px) {
  .site-layout .ant-layout-content {
    background: #fff;
  }

  #ILTS-REPORT-PDF .ant-table-wrapper {
    width: 200%;
  }
}

/* START - notification */
.notificationBar {
  text-align: center;
  width: 100%;
  overflow: hidden;
  margin: auto;
  background-color: #b64d4a;
  text-transform: uppercase;
  padding: 15px;
}

.notificationMsg {
  color: #ffffff;
  font-family: Roboto, Open Sans, Helvetica Neue, sans-serif;
}
p.version-print {
  color: #fff;
}
.notificationMsg a {
  font-weight: bold;
  /* color: #fa8c15; */
  /* color: #003365; */
  color: white;
  text-decoration: underline;
}

/* END - notification */

.verification-sidebar .logo,
.verification-sidebar li.ant-menu-item,
.verification-sidebar li.ant-menu-submenu {
  pointer-events: none;
  opacity: 0.5;
}
.verification-sidebar .logo,
.verification-sidebar ul {
  cursor: not-allowed;
}
