.steps-content {
  min-height: 200px;
  margin-top: 15px;
  padding: 10px;
  /* padding-top: 40px; */
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  overflow: auto;
  height: calc(100vh - 72px - 32px - 30px - 90px);
}

@media screen and  (max-width: 700px){
  .steps-content {
height: 120vh;
  }
}

.gx-app-login-content.verification {
  width: 100%;
  overflow-y: scroll;
  max-height: calc(
    100vh - 72px - 48px - 32px - 32px - 30px - 51px - 50px - 72px
  );
}

.steps-action {
  margin-top: 24px;
}

.text-verification {
  display: flex;
  font-size: 18px;
  font-weight: normal;
  /* padding: 0 0 0 20px; */
}

.ant-input:placeholder-shown.verification-input-field {
  display: flex;
  max-width: 50%;
  max-width: 365px;
}

.verification-step3-input-field {
  padding: 0 0 0 20px;
}

.radio-verification.ant-radio-group {
  padding: 0 0 0 20px;
}

.checkbox-verification.ant-checkbox-group {
  padding: 0 0 0 0px;
}

.verification-from {
  overflow-y: scroll !important;
  height: 240px !important;
}

.annual {
  justify-content: center;
}
.agree-wrapper {
  display: flex;
}

.agree-content {
  font-size: 14px;
  text-align: start;
  margin: 0px 0px 10px 10px;
}

.verification .ant-form label {
  font-size: 16px !important;
}

.collapse .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  display: flex;
}

.checkbox {
  display: flex;
  justify-content: flex-start;
}

.collapse .ant-radio-group {
  padding: 0 0 0 20px;
}

.collapse .ant-form-item-explain {
  display: flex;
  padding: 0 0 0 20px;
}

.collapse .ant-checkbox-group {
  display: flex;
  padding: 0 0 0 20px;
}

.require-star {
  color: red;
}

.text-verification .ant-form-item-control-input-content {
  display: flex;
  padding: 0 0 0 20px;
  max-width: 365px;
}

.risk-profile-form {
  text-align: justify;
  font-size: 14px;
}
.checkedEmail {
  justify-content: right;
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}
/* .collapse-question{
  max-height:calc(100vh - 72px - 32px - 90px);
  overflow: scroll;
} */
.ant-input-number.verification-input-field {
  width: 100%;
}
.ant-input-number-input {
  text-align: center !important;
}
.ant-picker-input > input {
  text-align: center;
}
.ant-progress-inner {
  background-color: darkgray !important;
}
.progress {
  margin-right: 4rem;
}
.form.ant-form.ant-form-horizontal.risk-profile-form {
  text-align: center !important;
}
.gx-app-login-wrap.title-verification {
  height: auto;
}

.annual-radio-select {
  line-height: 3 !important;
}

.client-concent-checkbox-table {
  display: inline-table;
  margin: 20px 0;
}

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item {
  margin-bottom: 10px;
}

.content-wrapper
  .ant-steps.ant-steps-horizontal.ant-steps-small.ant-steps-label-horizontal {
  display: contents;
}
.modalClose {
  display: none;
}
.display-field{
  width: 100%;
}

@media (min-width: 320px) and (max-width: 719px) {
  .display-field {
    display: block !important;
  }
}
