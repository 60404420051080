.support{
  display: flex;
  height: calc(100vh - 300px);
  flex-direction: column;
  justify-content: space-between;
}
.elementor-text-center{
  text-align: center;
}
.elementor-head-text{
  text-align: center;
}
.support-img{
  height: 350px;
  width: 350px;
}
.divider{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rm-text{
  display: flex;
  justify-content: center;
}
.rm-icon{
  font-size: 50px;
  color: #f7c633;
}
.rm-text-desc{
  color: #013365;
  font-size: 16px;
  padding-top: 0px;
  margin: 0 30px;
}
.elementor-heading-text-rm{
  color: #013365;
  font-size: 16px;
  font-weight: bold;
  padding-top: 0px;
}
.elementor-heading-text{
  color: #013365;
  font-size: 36px;
  font-weight:bold;
  padding-top: 0px;
}
.support-phone{
  text-align: center;
}
.support-phone-text{
  align-content: left;
}
.hr-divider{
  margin-top: -30px;
}
.elementor-location{
  text-align: center;
   font-size: 25px;
   font-weight: 450;
}
.elementor-address{
  text-align: center;
}
.elementor-working-hours{
  text-align: center;
   font-size: 25px;
   font-weight: 450;
}
.elementor-time{
  text-align: center;
}
.elementor-date{
  text-align: center;
}
.elementor-contact-us{
  text-align: center;
  font-size: 25px;
  font-weight: 450;
}
.elementor-email-section{
  text-align: center;
}
